
import {
  Parallax,
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Keyboard,
} from 'swiper/modules'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Btn from '@/components/Btn'
import utils from '@/mixins/utils'
import HTMLRender from '@/components/HTMLRender'
import 'swiper/swiper.min.css'
import 'swiper/modules/effect-fade.min.css'
import 'swiper/modules/pagination.min.css'
import 'swiper/modules/autoplay.min.css'
import 'swiper/modules/keyboard.min.css'
import 'swiper/modules/parallax.min.css'
import 'swiper/modules/navigation.min.css'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

Swiper.use([Pagination])
export default {
  mixins: [utils],
  components: {
    Swiper,
    SwiperSlide,
    Btn,
    HTMLRender,
  },
  directives: {
    swiper: directive,
  },
  setup() {
    return {
      modules: [
        Parallax,
        Navigation,
        Pagination,
        EffectFade,
        Autoplay,
        Keyboard,
      ],
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        allowTouchMove: true,
        keyboard: {
          enabled: true,
        },
        parallax: this.data.effect === 'parallax',
        effect: this.data.effect === 'fade' ? 'fade' : null,
        modules: [
          Parallax,
          Navigation,
          EffectFade,
          Pagination,
          Autoplay,
          Keyboard,
        ],
        pagination: { el: '.swiper-pagination', clickable: true },
        autoplay: this.data.autoplay
          ? {
              delay: this.data.delay || 5000,
              disableOnInteraction: false,
            }
          : false,
        on: {
          init() {
            this.slideToLoop(0, 0)
            this.el.style.setProperty(
              '--swiper-wrapper-transition-timing-function',
              'cubic-bezier(0.76, 0, 0.24, 1)'
            )
          },
          touchStart() {
            this.el.style.setProperty(
              '--swiper-wrapper-transition-timing-function',
              'cubic-bezier(0.25, 1, 0.5, 1)'
            )
          },
          transitionEnd() {
            this.el.style.setProperty(
              '--swiper-wrapper-transition-timing-function',
              'cubic-bezier(0.76, 0, 0.24, 1)'
            )
          },
        },
        speed: this.data.speed || 300,
        slidesPerView: 1.2,
        centeredSlides: true,
        breakpoints: {
          640: {
            slidesPerView: 1,
            centeredSlides: false,
            spaceBetween: 0,
            coverflowEffect: false,
          },
        },
        navigation: this.data.arrows
          ? {
              nextEl: '#swiper-button-next-' + this._uid,
              prevEl: '#swiper-button-prev-' + this._uid,
            }
          : false,
      },
    }
  },
  methods: {
    getUrl(image, width) {
      if (image && image.attributes.mime === 'image/gif') {
        return image.attributes.url
      } else if (this.$imagekit) {
        return this.$imagekit.url({
          src: image.attributes.url,
          transformation: [
            {
              width,
              quality: '70',
              dpr: 'auto',
              crop: 'fill',
            },
          ],
        })
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.tl = this.$gsap
      .timeline({
        paused: true,
        scrollTrigger: {
          trigger: this.$refs.swiperOuter,
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        },
      })
      .fromTo(
        this.$refs.swiperMedia,
        {
          y: '0%',
          webkitFilter: 'brightness(100%)',
          filter: 'brightness(100%)',
          scale: 1.05,
          ease: 'Linear.easeNone',
        },
        {
          y: '30%',
          webkitFilter: 'brightness(30%)',
          filter: 'brightness(30%)',
          scale: 1,
          ease: 'Linear.easeNone',
        }
      )
  },
}
